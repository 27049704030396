// @flow
import React from 'react';
import FrameContext from './Context';

import { getConfig } from '../../libs/firebase';

const Mouseflow = () => {
	const { document: documentFrame, window: windowFrame } = React.useContext(
		FrameContext,
	);

	// Analitycs
	React.useEffect(() => {
		let isUnmount = false;

		(async () => {
			const { mouseflowProjectID } = await await getConfig();

			if (
				!isUnmount &&
				mouseflowProjectID !== 'null' &&
				documentFrame &&
				windowFrame
			) {
				windowFrame._mfq = windowFrame._mfq || [];
				(function() {
					const mf = documentFrame.createElement('script');
					mf.type = 'text/javascript';
					mf.defer = true;
					mf.src = `//cdn.mouseflow.com/projects/${mouseflowProjectID}.js`;
					documentFrame.getElementsByTagName('head')[0].appendChild(mf);
				})();
			}
		})();

		return () => {
			isUnmount = true;
		};
	}, [documentFrame, windowFrame]);

	return null;
};

export default React.memo<{}>(Mouseflow);
