// @flow
import React from 'react';
import _ from 'lodash/fp';
import emptyObject from 'empty/object';
import { Box, Flex } from '@graphite/uneon';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as icons from '@fortawesome/free-solid-svg-icons';
import useDynamicStyle from 'Widget/libs/use-dynamic-style';
import { getBoxSx, getDesignSx } from '@graphite/selectors';
import type { TSx, TDesign } from '@graphite/types';

import type { TConnectProps } from './constants/types';

import { getButtonAlignSx } from './libs/getButtonAlignSx';

const Icon = styled(FontAwesomeIcon)``;

const baseButtonSx: TSx = {
	borderWidth: 0,
	borderRadius: 0,
	borderColor: 'transparent',
	outline: 'none',
	background: 'none',
	display: 'inline-flex',
	alignItems: 'center',
	justifyContent: 'center',
	textDecoration: 'none',
	padding: 0,
};

const Button = (props: TConnectProps, ref) => {
	const {
		data,
		position,
		colorspec,
		effectspec,
		gridspec,
		widgetspec,
		dragContainer,
		dragFlip,
		direction,
		isPassive = false,
		children,
	} = props;

	const boxSx = gridspec
		? getBoxSx({ data, position, direction, gridspec })
		: emptyObject;

	const buttonSx: TSx = React.useMemo(() => {
		if (!gridspec || !widgetspec || !colorspec || !effectspec) return emptyObject;

		const alignSx = getButtonAlignSx({ data, gridspec });

		if (!data.designId) return _.assign(baseButtonSx, alignSx);

		const custom: ?TDesign = (data.designs && data.designs[data.designId]) || null;
		const design: ?TDesign =
			custom || widgetspec.button.find(d => d._id === data.designId);

		if (!design) return _.assign(baseButtonSx, alignSx);

		const designSx = getDesignSx({
			design,
			widgetspec,
			colorspec,
			effectspec,
			gridspec,
		});

		return _.mergeAll([baseButtonSx, designSx, alignSx]);
	}, [data, colorspec, widgetspec, effectspec, gridspec]);

	const { link, text = { isShown: false }, icon = { isShown: false } } = data;

	const boxProps = React.useMemo(() => {
		if (isPassive || !(link && link.href)) {
			return { as: 'button', sx: buttonSx };
		}
		return {
			as: 'a',
			href: link.href,
			target: link.isNewTab ? '_blank' : '_self',
			sx: buttonSx,
		};
	}, [buttonSx, isPassive, link]);

	const dynamicStyle: ?TSx = useDynamicStyle(data.style);

	const otherProps = _.assign(dragContainer, dragFlip);

	return (
		<>
			{/* eslint-disable-next-line react/jsx-props-no-spreading */}
			<Box sx={boxSx} style={dynamicStyle} ref={ref} {...otherProps}>
				{/* eslint-disable-next-line react/jsx-props-no-spreading */}
				<Flex {...boxProps}>
					{icon && icon.isShown && icon.position === 'left' && (
						<Icon icon={icons[icon.name]} />
					)}
					{children ||
						(text && text.isShown ? <span>{text.label}</span> : null)}
					{icon && icon.isShown && icon.position === 'right' && (
						<Icon icon={icons[icon.name]} />
					)}
				</Flex>
			</Box>
		</>
	);
};

export default React.memo<TConnectProps>(React.forwardRef(Button));
